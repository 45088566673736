import React, { CSSProperties, useRef } from "react";
import cn from "classnames";
import { SVG } from "~components";
import { useApp, useTrapFocus } from "~hooks";
import * as styles from "./styles.module.scss";
import { Link, graphql, useStaticQuery } from "gatsby";
import { ISettings } from "~schemas";
import MenuItem from "./components/MenuItem";

interface IQueryResults {
  sanitySettings: ISettings;
}

const Header = () => {
  const {
    isMenuOpen,
    setIsMenuOpen,
    setIsToolboxOpen,
    toolbox,
    isHeaderVisible,
    setIsHeaderVisible
  } = useApp();

  const {
    sanitySettings: { menuItems }
  }: IQueryResults = useStaticQuery(query);

  const containerRef = useRef<HTMLDivElement>(null);
  useTrapFocus(isMenuOpen, containerRef);

  const headerTransformPosition: () => CSSProperties = () => {
    if (isHeaderVisible) {
      return { transform: `translateY(0)` };
    }

    return { transform: `translateY(-100%)` };
  };

  return (
    <div ref={containerRef} className={styles.container}>
      <div
        onClick={() => setIsMenuOpen(false)}
        aria-hidden
        className={cn(styles.bgOverlay, { [styles.visible]: isMenuOpen })}
      />

      <header className={styles.header} style={headerTransformPosition()}>
        <Link
          onClick={() => setIsMenuOpen(false)}
          to="/"
          className={styles.homeLink}
          aria-label="Home"
          onFocus={() => setIsHeaderVisible(true)}
        >
          <SVG svg="wordmark" className={styles.wordmark} />
        </Link>

        <div className={styles.rightButtons}>
          <button
            className={styles.menuButton}
            onClick={() => setIsToolboxOpen((prev) => !prev)}
            aria-label="Open/close toolbox menu"
          >
            <SVG
              svg="backpack"
              className={cn(styles.menuButtonIcon, styles.active)}
            />
            {toolbox?.[0] && (
              <div className={styles.contactsCount}>{toolbox.length}</div>
            )}
          </button>

          <button
            onClick={() => setIsMenuOpen((prev) => !prev)}
            type="button"
            className={cn(styles.menuButton, styles.smaller)}
            aria-label="Open/close navigation menu"
            onFocus={() => setIsHeaderVisible(true)}
          >
            <SVG
              svg="hamburger"
              className={cn(styles.menuButtonIcon, {
                [styles.active]: !isMenuOpen
              })}
            />
            <SVG
              svg="x"
              className={cn(styles.menuButtonIcon, styles.menuButtonIconX, {
                [styles.active]: isMenuOpen
              })}
            />
          </button>
        </div>
      </header>

      <div className={cn(styles.navContainer, { [styles.open]: isMenuOpen })}>
        <nav className={styles.nav}>
          <ul>
            {menuItems.map((menuItem) => (
              <li key={menuItem._key}>
                <MenuItem
                  title={menuItem.title}
                  page={menuItem.page}
                  backgroundColor={menuItem.backgroundColor}
                  textColor={menuItem.textColor}
                  highlightColor={menuItem.highlightColor}
                />
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;

const query = graphql`
  query HeaderQuery {
    sanitySettings {
      menuItems {
        _key
        title
        textColor {
          hex
        }
        page {
          slug {
            current
          }
        }
        highlightColor {
          hex
        }
        backgroundColor {
          hex
        }
      }
    }
  }
`;
