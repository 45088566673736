import React, { useRef } from "react";
import { useApp, useTrapFocus, useDisableFocus } from "~hooks";
import cn from "classnames";
import { SVG } from "~components";
import * as styles from "./styles.module.scss";
import RequestContactsForm from "./components/RequestContactsForm";
import { Link } from "gatsby";

const ToolboxAside = () => {
  const { isToolboxOpen, setIsToolboxOpen, toolbox, setToolbox } = useApp();

  const closeAside = () => {
    setIsToolboxOpen(false);
  };

  const hasContacts = toolbox.length > 0;

  const removeContact = (id: string) => {
    setToolbox((prev) => prev.filter((contact) => contact._id !== id));
  };

  const containerRef = useRef<HTMLDivElement>(null);
  useTrapFocus(isToolboxOpen, containerRef);
  useDisableFocus(!isToolboxOpen, containerRef);

  return (
    <div
      className={cn(styles.container, { [styles.active]: isToolboxOpen })}
      aria-hidden={!isToolboxOpen}
    >
      <div className={styles.backdrop} onClick={closeAside} aria-hidden />

      <div className={styles.content} ref={containerRef}>
        <div className={styles.contactsList}>
          <div className={styles.topRow}>
            <h2 className="h2">Backpack</h2>
            <button
              onClick={closeAside}
              className={cn(`caption`, styles.closeButton)}
            >
              <SVG svg="x" />
            </button>
          </div>

          {!hasContacts && (
            <div className={cn(styles.text, `h4`)}>
              <p>Your Backpack is empty!</p>
              <p>
                Check out our{" "}
                <Link
                  onClick={closeAside}
                  to="/startup-toolbox"
                  className={styles.advisorNetworkLink}
                >
                  Startup Backpack
                </Link>{" "}
                to add resources.
              </p>
            </div>
          )}

          {toolbox.map(
            ({ name, subheading1, subheading2, _id, description }) => {
              const getCaptionContent = () => {
                const bestCase = [subheading1, subheading2]
                  .filter(Boolean)
                  .join(", ");

                if (bestCase) return bestCase;

                const getTruncatedDescription = () => {
                  const descriptionSnippetMaxCharacters = 130;

                  const isDescriptionLongerThanMaxLength =
                    description.length > descriptionSnippetMaxCharacters;

                  if (!isDescriptionLongerThanMaxLength) return description;

                  const truncatedDescription = description
                    .substring(0, descriptionSnippetMaxCharacters)
                    .split(" ")
                    .slice(0, -1)
                    .join(" ");

                  return `${truncatedDescription}...`;
                };

                return getTruncatedDescription();
              };

              return (
                <div className={styles.contactItem} key={_id}>
                  <div>
                    <h3 className={cn(`b1`, styles.contactName)}>{name}</h3>
                    <div className="caption">{getCaptionContent()}</div>
                  </div>

                  <div>
                    <button
                      className={cn("caption", styles.removeButton)}
                      onClick={() => removeContact(_id!)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            }
          )}
        </div>

        {toolbox?.[0] && <RequestContactsForm />}
      </div>
    </div>
  );
};

export default ToolboxAside;
